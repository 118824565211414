<template>
  <div class="p-4 txt-grey-900 fs-14 fw-500">
    <div class="font-bold fs-24 fw-700 mb-3">{{ $t("Danh sách dịch vụ") }}</div>

    <div class="bg-white rounded p-6 h-auto overflow-auto mt-2">
      <div class="flex justify-between mb-3">
        <div class="flex flex-col md:flex-row justify-between w-full items-end">
          <div class="flex flex-col md:flex-row items-end gap-4">
            <div class="flex items-center border rounded-md border-grey-300 p-2 mr-2 w-60">
              <IconSearch class="cursor-pointer" />
              <input
                v-model="valueSearch"
                @keypress.enter="getListService"
                class="border-0 txt-grey-500 ml-2 focus:outline-none"
                :placeholder="$t('lbl_enter_name')"
              />
            </div>
            <div>
              <span class="mr-3 block">Danh mục</span>
              <el-select
                v-model="params.cate"
                placeholder="Tất cả"
                @change="getListService"
                filterable
                clearable
              >
                <el-option
                  v-for="item in serviceCategories"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <!-- <div>
              <span class="mr-3 block">Đơn vị cung cấp</span>
              <el-select
                v-model="params.provider_id"
                placeholder="Tất cả"
                @change="getListService"
                filterable
                clearable
              >
                <el-option
                  v-for="item in serviceProviders"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>-->
          </div>
          <el-button
            class="h-10"
            type="primary"
            @click="$router.push({ name: 'CreateServiceLocal' })"
          >Thêm mới</el-button>
        </div>
      </div>
      <div>
        <el-table
          :data="listService"
          style="width: 100%"
          row-class-name="text-black text-sm cursor-pointer"
          header-cell-class-name="text-black text-base font-semibold"
          @row-click="handleEdit"
        >
          <el-table-column prop="local_name" :label="$t('Tên dịch vụ')" width="350px"></el-table-column>
          <el-table-column prop="service_catalog.services_code" :label="$t('Mã dịch vụ')"></el-table-column>
          <el-table-column prop="service_catalog.service_category.name" :label="$t('Danh mục')"></el-table-column>
          <el-table-column
            prop="service_catalog.service_provider.name"
            :label="$t('Đơn vị cung cấp')"
          ></el-table-column>
          <el-table-column
            prop="local_price"
            :label="$t('Giá dịch vụ')"
            align="right"
            :formatter="formatPrice"
          ></el-table-column>
          <!-- <el-table-column label="Hiển thị" align="right">
            <template slot-scope="scope">
              <el-checkbox @change="handleShow(scope.row)"></el-checkbox>
            </template>
          </el-table-column>-->
        </el-table>
        <AppPagination :paginationProps="pagination" @onChangePagination="getListService" />
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonHoDo } from '@/components/Button'
import { IconSearch } from '@/components/Icon'
import { AppPagination } from '@/components/Common'
import appUtils from '../../utils/appUtils'
import { INDICATION_TYPES, SERVICE_GROUP } from '@/utils/constants'

export default {
  components: {
    ButtonHoDo,
    IconSearch,
    AppPagination
  },
  name: 'ListServiceTemplate',

  data () {
    return {
      valueSearch: '',
      listService: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      params: {
        cate: '',
        provider_id: null
      },
      isChecked: false,
      appUtils,
      INDICATION_TYPES,
      SERVICE_GROUP,
      serviceProviders: [],
      serviceCategories: []
    }
  },
  mounted () {
    this.getListService()
    this.getListServiceProvider()
    this.getListServiceCategory()
  },
  watch: {},
  computed: {},
  methods: {
    async getListService (data) {
      if (!this.$globalClinic && !this.$globalClinic.id) return
      let self = this
      const params = {
        ws_id: this.$globalClinic.id,
        org_id: this.$globalOrg?.id,
        page_num: data?.currentPage || this.pagination.currentPage,
        page_size: data?.pageSize || this.pagination.pageSize,
        sort_by: 'created_at',
        order: 'desc'
      }
      if (this.valueSearch) {
        params.keyword = this.valueSearch
      }
      if (this.params.cate) {
        params.cate = this.params.cate
      }
      if (this.params.provider_id) {
        params.provider = this.params.provider_id
      }

      const r = await this.$rf
        .getRequest('DoctorRequest')
        .getLocalService(params)
        .then(r => {
          self.listService = r.data?.data || []
          const metaData = r.data?.page || {}
          self.pagination.totalItems = metaData.total || 0
          self.pagination.currentPage = metaData.page_num || 1
          self.pagination.pageSize =
            Number(metaData.page_size) || this.pagination.pageSize

          return r
        })
        .catch(e => {
          console.log(e)

          return false
        })
      return r
    },
    async getListServiceProvider () {
      if (!this.$globalClinic?.org_id) return
      try {
        const params = {
          org_id: this.$globalClinic?.org_id,
          page_num: 1,
          page_size: 1000,
          sort_by: 'created_at',
          order: 'desc'
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceProvider(params)
        const responseData = response.data || {}
        this.serviceProviders = responseData?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async getListServiceCategory () {
      if (!this.$globalClinic?.org_id) return
      try {
        const params = {
          org_id: this.$globalClinic?.org_id,
          page_num: 1,
          page_size: 1000,
          sort_by: 'created_at',
          order: 'desc'
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceCategory(params)

        const responseData = response.data || {}
        this.serviceCategories = responseData?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    formatPrice (row) {
      return `${appUtils.numberFormat(row.local_price)} VNĐ`
    },
    formarDate (row) {
      return appUtils.formatDate(row.created_at)
    },
    handleShow (value) {
      console.log('🚀 ~ handleShow ~ value:', value)
    },
    handleEdit (row) {
      this.$router.push({ name: 'EditServiceLocal', params: { id: row.id } })
    }
  }
}
</script>

<style scoped>
::placeholder {
  color: #98a2b3;
}

.border-bottom-2 {
  border-bottom: 2px solid #20419b !important;
}

.custom:hover {
  background-color: #e4e7ec;
}

.txt-red {
  color: #ff0e0e;
}

.bg-demo {
  background-color: #f6e6ad;
}

.border-custom {
  border: 1px solid #e4e7ec;
}
</style>
